/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 应付合同金额/数量 33
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-10 10:11:52
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import { Pagination } from 'antd';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './contractAmountorPay.scss';
import { useMount } from '../../../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function ApContractAmountorPay({ msg }) {
  ApContractAmountorPay.propTypes = {
    msg: PropTypes.object,
  };
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 0); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      // console.log('res', res);
      // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('info')) {
        const arr1 = res.info.list || [];
        msg.info.list = arr1;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent(arr1);
      }
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }
  return (
    <div className={loadingStatus ? 'v2200-ApContractAmountorPay v1123_relative' : 'v2200-ApContractAmountorPay'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <div className="billList" style={{ display: Array.isArray(content) && content.length ? 'block' : 'none' }}>
        <div className="item-main">
          {
            content.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="content-item" key={index}>
                <div className="item-title">
                  <div>{item.contract_name}</div>
                  <div className="item-title_realname">
                    经办人：
                    {item.realname}
                  </div>
                </div>
                <div className="item-content">
                  <div className="txt-des">
                    <div className="des-item">
                      <div className="icon-txt">
                        <div>{item.supplier_name}</div>
                      </div>
                      <div className="des-money">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${item.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </div>
                      <div className="flex1">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${item.yingfu_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </div>
                    </div>
                    <div className="des-item des-item_title">
                      <div className="fee-number">
                        {item.contract_date}
                        ～
                        {item.maturity_date}
                      </div>
                      <div className="des-money">合同金额</div>
                      <div className="flex1">合同应付</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {
            Number(totalPage) > msg.info.per_page 
            ? (
              <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
            ) : ''
          }
        </div>
      </div>
    </div>
  );
}
