/*
移动端条形图组件
 * @Author: your name
 * @Date: 2021-10-25 19:56:45
 * @LastEditTime: 2022-06-23 18:27:52
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileBar\index.js
 */
/*eslint-disable*/
import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  UniversalTransition
]);
export default function MobileBar({ msg, id }) {
  let name = []
  let data = []
  msg.info.list.forEach(res => {
    name.push(res.y)
    data.push(res.x)
  })
  const max = _.max(data.map((item) => {
    return Number(item)
  }));
  let RMB;
  if (max / 1000 > 10) {
    RMB = '万元';
  } else {
    RMB = '元'
  }
  useEffect(() => {
    let timer2;
    const timer = setTimeout(() => {
      var chartDom = document.getElementById(id);
      const height = 55 * (msg.info.list.length);
      chartDom.style.width = 'calc(540rem/75)';
      chartDom.style.height = `calc(${height}rem/75)`;
      // chartDom.style.paddingTop = 'calc(40rem/75)'
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          borderColor: 'transparent',
          backgroundColor: 'rgba(62, 70, 77, 0.8)',
          formatter(params) {
            return `
                  <div style='color: white; font-size: 14px'>
                      员工: ${params[0].name} <br />
                      金额: ${params[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}元
                  <div/>
                `
          },
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
        }
        },
        grid: {
          left: '0%',
          right: '3%',
          bottom: '-10%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          type: 'category',
          trigger: true,
          data: name,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontWeight: 400,
            color: '#909399',
            formatter: function (value, index) {
              if (value.length > 5) {
                return  value.substring(0, 4) + "...";
              } else {
                return value
              } 
            }
          },
          inverse: true,
        },
        {
          show: true,
          data: data,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontWeight: 400,
            color: '#909399',
            formatter (value) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          },
          inverse: true,
        }],
        series: [
          {
            type: 'bar',
            data: data,
            barWidth: 15,
            itemStyle: {
              borderRadius: [0, 7, 7, 0],
              color: '#3377FF'
            },
            showBackground: true,
            backgroundStyle: {
              color: '#EAF2FE ',
              borderRadius: [0, 7, 7, 0],
            },
          }
        ]
      };
      option && myChart.setOption(option);
      timer2 = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      window.addEventListener('resize', () => {
        myChart.resize();
      })
    }, 300)
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    }
  }, [])

  return (
    <div />
  );
}
