/*
移动语音动画组件
 * @Author: your name
 * @Date: 2021-10-21 14:30:07
 * @LastEditTime: 2022-01-25 18:19:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\MobileMask\index.js
 */
import React from 'react';
import '@/style/mobile-mask.scss';
import PropTypes from 'prop-types';
import maikefengPC from '@/images/maikefengPC.png';

export default function MobileMask({ mobileVoice, mobileVoiceTime }) {
  MobileMask.propTypes = {
    mobileVoice: PropTypes.bool,
    mobileVoiceTime: PropTypes.number,
  };
  return (
    <div className={mobileVoice ? 'mobile' : 'none'}>
      <div className="mobile-mask">
        <div className="maikefeng">
          <img src={maikefengPC} alt="" />
        </div>
        <div>Signser正在聆听…</div>
        <div>{mobileVoiceTime >= 10000 ? '试试这样说：查询逾期合同、查询我跟进的合同' : `您还可以再说${Math.floor(mobileVoiceTime / 1000)}秒`}</div>
      </div>
    </div>
  );
}
