/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * pc端 25查应收发票缴税信息  26查应收发票明细信息
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-10 10:09:56
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import { Pagination } from 'antd';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './taxDetail.scss';
import { useMount } from '../../../../utils/hooks';
import { getIconImg } from '../utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function billTaxDetail({ msg }) {
  billTaxDetail.propTypes = {
    msg: PropTypes.object,
  };
  // console.log(msg, 'billTaxDetail');
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 0); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      const arr1 = res.info.list || [];
      // console.log(arr1, 'res', res);
      msg.info.list = arr1;
      msg.info.current_page = res.info.current_page || 1;
      msg.info.per_page = res.info.per_page || 3;
      setTotalPage(res.info.total);
      setCurrent(res.info.current_page || 1);
      setPrePage(res.info.per_page || 3);
      setContent(arr1);
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }
  return (
    <div className={loadingStatus ? 'v2110-taxDetail v1123_relative' : 'v2110-taxDetail'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <div className="billList">
        <div className="item-main">
          {
            content.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="content-item" key={index}>
                <div className="item-title">{item.custom_name}</div>
                <div className="item-content">
                  <div className="txt-des">
                    <div className="des-item">
                      <div className="icon-txt">
                        <img src={getIconImg(item.bill_type)} alt="" />
                        <div>{item.bill_type}</div>
                      </div>
                      <div className="des-money">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </div>
                      <div className="flex1">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${item.tax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                        （
                        {item.tax_rate}
                        ）
                      </div>
                    </div>
                    <div className="des-item des-item_title">
                      <div className="fee-number">
                        {item.billing_number}
                        <span className="ml5">|</span>
                        {item.billing_date}
                      </div>
                      <div className="des-money">开票金额</div>
                      <div className="flex1">税额（税率）</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {
            Number(totalPage) > msg.info.per_page 
            ? (
              <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
            ) : ''
          }
        </div>
      </div>
    </div>
  );
}
