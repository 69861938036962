/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/*
pc端第一次进入页面是展示的样式
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-07-05 17:30:20
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { useMount } from '../../../utils/hooks';
import { getQuikQueryBillStyle } from '../../../utils/utils';
import quickQueryV2300DefaultRefresh from '@/images/quickQueryV2300_defaultRefresh.png';
import quickQueryV2300Refresh from '@/images/quickQueryV2300_refresh.png';

export default function FirstSentence({ pcSend, msg }) {
  FirstSentence.propTypes = {
    pcSend: PropTypes.func,
    msg: PropTypes.object,
  };
  const initArr = Array.isArray(msg?.messages) ? msg?.messages : [];
  // eslint-disable-next-line
  let [arr, setArr] = useState(initArr); // 渲染数据的list
  // eslint-disable-next-line prefer-const
  let [content, setContent] = useState(msg || {}); // 渲染数据的list
  const [random, setRandom] = useState(Math.floor(Math.random() * arr.length));
  useMount(() => {
    setArr(Array.isArray(msg?.messages) ? msg?.messages : []);
    setContent(msg || {});
  });
  // 点击换一批页面上的数据发生改变
  function change() {
    setRandom(Math.floor(Math.random() * arr.length));
  }
  /**
   * @description App组件传递点击的内容
   * @param {*} e 事件对象 通过e.target.innerText获取标签内容
   */
  function send(e) {
    pcSend(e.target.innerText.split('.')[1]);
  }
  return (
    <>
      <div className="query-content">
        <div className="queryH64">
          <div className="bill-title">
            <img className="bill-img" src={getQuikQueryBillStyle(content.name).img} />
            <span>{content.name || ''}</span>
          </div>
          <div className="bill-line" style={{ background: getQuikQueryBillStyle(content.name).color }} />
          <div className="bill-content">
            <div className="recommend-txt">
              <div onClick={(e) => send(e)}>
                1.
                {random === 0 ? arr[random + 1] : arr[random - 1]}
              </div>
              <div onClick={(e) => send(e)}>
                2.
                {arr[random]}
              </div>
              <div onClick={(e) => send(e)}>
                3.
                {/* eslint-disable-next-line */}
                {random + 1 === arr.length ? arr[random - 2] : (random === 0 ? arr[random + 2] : arr[random + 1])}    
              </div>
            </div>
            {
              Array.isArray(arr) && arr.length > 3 ? (
                <div className="refresh-main" onClick={change}>
                  <img className="refresh-Default" src={quickQueryV2300DefaultRefresh} />
                  <img className="refresh-img" src={quickQueryV2300Refresh} />
                  <span>换一换</span>
                </div>
              ) : <div className="refresh-main" />
            }
          </div>
        </div>
      </div>
    </>
  );
}
