/* eslint-disable react/no-array-index-key */
/*
 * @Author: your name
 * @Date: 2022-03-29 10:37:30
 * @LastEditTime: 2022-04-14 17:18:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\MyApproval\MYApprovalLIst.js
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Pagination, DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import '@/style/myApproval.scss';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import selDateIcon from '@/images/approval_selDate.png';
import emptyImg from '@/images/approvalPC_empty.png';

const { RangePicker } = DatePicker;
export default function MyApprovalList({ msg, id, activeKey }) {
  MyApprovalList.propTypes = {
    msg: PropTypes.object,
    id: PropTypes.string,
    activeKey: PropTypes.string,

  };
  const selRecentDate = [
    { txt: '最近一周', type: '1', date: [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')] },
    { txt: '最近一个月', type: '2', date: [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')] },
    { txt: '最近三个月', type: '3', date: [moment().subtract(3, 'months').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')] },
    { txt: '最近半年', type: '4', date: [moment().subtract(0.5, 'years').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')] },
    { txt: '最近一年', type: '5', date: [moment().subtract(1, 'years').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')] },
  ];
  const [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [currenDate, setCurrentDate] = useState(null); // 当前用户选择日期
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 5); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.totalPage); // 一共多少页
  const [pickerDateDialog, setPickerDateDialog] = useState(false); // 选择日期弹窗 
  const [loadingStatus, setLoadingStatus] = useState(false);
  const rangePickerRef = useRef(null); // 日期组件移出监听

  const titleDOm = document.getElementById(id);
  if (msg.state === activeKey && titleDOm.innerHTML !== msg.title) {
    titleDOm.innerHTML = msg.title || '';
  }
  // 监听日期选择框移出
  useOnClickOutside(rangePickerRef, () => setPickerDateDialog(false));

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage, date) {
      // if (current > Number(totalPage) || current === 0) return;
      setLoadingStatus(true);
      const params = {
        ...msg.info.parameter,
        per_page: perPage,
        current_page: currentPage,
        state: msg.state,
      };
      if (Array.isArray(date) && date.length) {
        params.date = JSON.stringify([`${date[0]}/${date[1]}`]); // 日期
      }
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params,
      }).then((res) => {
        const title = document.getElementById(id);
        if (title.innerHTML !== res.title && msg.state === activeKey) {
          title.innerHTML = res.title || '';
          msg.title = res.title;
        }
        // pending_count 待审批数量 refuse_count 驳回数量 pass_count 	通过数量
        switch (res.info.parameter.state || msg.state) {
          case '待审批':
            setTotalPage(res.info.pending_count);
            msg.totalPage = res.info.pending_count;
            msg.changeNavTotal('待审批', res.info.pending_count);
            break;
          case '已驳回':
            setTotalPage(res.info.refuse_count);
            msg.totalPage = res.info.refuse_count;
            msg.changeNavTotal('已驳回', res.info.refuse_count);
            break;
          case '已通过':
            setTotalPage(res.info.pass_count);
            msg.totalPage = res.info.pass_count;
            msg.changeNavTotal('已通过', res.info.pass_count);
            break;  
          default:
            setTotalPage(res.info.pending_count);
            msg.totalPage = res.info.pending_count;
            msg.changeNavTotal('待审批', res.info.pending_count);
            break;
        }
        msg.info.list = res.info.list;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 5;
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 5);
        setContent([...Object.values(res.info.list)]);
        setLoadingStatus(false);
      });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page, currenDate);
  }
  // 选择日期 点击日历
  async function selPickerDate(date, dateString) {
    setPickerDateDialog(false);
    setCurrentDate(dateString);
    setCurrent(1);
    setPageStatus(false);
    getDateList(prePage, 1, dateString);
  }
  // 日期组件额外的页脚
  function renderExtraFooter() {
    return (
      <div className="date-footer">
        {
          selRecentDate.map((el, index) => (
            <div key={index} onClick={(e) => selRecentDay(e, el.date)}>{ el.txt }</div>
          ))
        }
      </div>
    );
  }
  // 选择最近日期
  function selRecentDay(ele, date) {
    ele.stopPropagation();
    setPageStatus(false);
    setCurrentDate(date);
    setCurrent(1);
    setPickerDateDialog(false);
    getDateList(prePage, 1, date);
  }

  // 日期选择框移出 Hook
  function useOnClickOutside(ref, callback) {
    useEffect(() => {
      function handler(event) {
        if (!ref.current?.contains(event.target)) {
          callback();
        }
      }
      window.addEventListener('click', handler);
      return () => window.removeEventListener('click', handler);
    }, [callback, ref]);
  } 

  return (
    <div className={loadingStatus ? 'myApproval v1123_relative' : 'myApproval'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      { 
        <>
          <div className="item-main mt0">
            {/* 头部标题 */}
            <div className="approval-header">
              <div className="header-item pL32">
                审批标题 
              </div>
              <div className="header-item" ref={rangePickerRef}>
                <div className="time-date" onClick={() => setPickerDateDialog(!pickerDateDialog)}>
                  <div>
                    {activeKey === '待审批' ? '发起时间' : '审批时间'}
                  </div>
                  <img src={selDateIcon} alt="" />
                </div>
                <div style={{ display: pickerDateDialog ? 'block' : 'none' }}>
                  <RangePicker defaultValue={Array.isArray(currenDate) && currenDate.length ? [moment(currenDate[0], 'YYYY-MM-DD'), moment(currenDate[1], 'YYYY-MM-DD')] : ''} value={Array.isArray(currenDate) && currenDate.length ? [moment(currenDate[0], 'YYYY-MM-DD'), moment(currenDate[1], 'YYYY-MM-DD')] : ''} format="YYYY-MM-DD" picker="date" onChange={(date, dateString) => selPickerDate(date, dateString)} locale={locale} placement="bottomRight" suffixIcon={<img src={selDateIcon} alt="" />} className="sponsor-time" open={pickerDateDialog} bordered={false} size={0} placeholder="" getPopupContainer={(triggerNode) => triggerNode.parentNode} renderExtraFooter={() => renderExtraFooter()} />
                </div>
              </div>
            </div>
            {/* 表格内容 */}
            <div className="approval-content">
              {
                totalPage ? (
                  <>
                    {content.map((item, index1) => (
                      <div className="content-item" key={index1}>
                        <div className="approval-title pL32">{item.describe}</div>
                        <div className="approval-deal">
                          <span className="deal-statusTxt">{item.add_time}</span>
                        </div>
                      </div>
                    ))}
                    <Pagination current={current} hideOnSinglePage pageSize={prePage} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="pageination-main" size="small" showSizeChanger={false} total={totalPage} />
                  </>
                ) : (
                  <div className="empty-main">
                    <img src={emptyImg} alt="" />
                    <div className="empty-txt">暂无数据</div>
                  </div>
                )
              }
            </div>
          </div>
        </>
      }
    </div>
  );
}
