/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * mobile发票回款
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-10 10:16:09
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './receivable.scss';
import { useMount } from '../../../../utils/hooks';
// eslint-disable-next-line max-len
import { getIconImg, initMobileArr, updMobileDrownFlag, setChatFooter } from '../../../v1.1.24_PC/bill/utils/index';
// eslint-disable-next-line
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';
import tipsImg from '@/images/tips-bill_20220426.png';

let start = false;
export default function MobileBillReceivableIndex({ msg, appendMessage, setSlotChatFooter }) {
  MobileBillReceivableIndex.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func,
    setSlotChatFooter: PropTypes.func,
  };
  let [force, setForce] = useState(initMobileArr(msg.info?.list || []));
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        let list = initMobileArr(res.info.list || []);
        if (current < 3) {
          force = [...force, ...list];
          setForce(force);
        } else {
          setForce([...force, ...list]);
        }
      });
    }
  }, [current]);

  useMount(() => {
    // 仅看有回款的发票、仅看没有回款的发票
    if (msg && msg.info && msg.info.parameter) {
      // eslint-disable-next-line no-prototype-builtins
      if (!msg.info.parameter.hasOwnProperty('only_receive') || msg.info.parameter.only_receive === null) {
        setChatFooter(msg, appendMessage, setSlotChatFooter);
      }
    }
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }

  // icon 展示数据
  async function handleShowList(index, flag) {
    let requestArr = [];
    if (!force[index].requestFlag) {
      const params = {
        relation_ids: force[index].relation_ids,
      };
      const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ar/bill/receivable/relevance`, { params });
      requestArr = getApiInfo?.info?.list;
    }
    requestArr = Array.isArray(requestArr) ? requestArr : [];
    force = updMobileDrownFlag(force, index, flag, requestArr);
    if (Array.isArray(pickArr) && pickArr.length) {
      setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
    }
    setForce(force);
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="mobileV2110BillReceivab" style={{ minWidth: 'calc(527rem/75)' }}>
      <div className="billList" style={{ paddingBottom: msg.info.total_page <= 1 ? '0' : 'calc(52rem/75)' }}>
        {(pickArr.length ? pickArr : force).map((item, index) => (
          // eslint-disable-next-line
          <div className="content-item" key={index}>
            <div className="item-header">
              <div className="customer-name">
                {item.custom_name}
              </div>
              <div className="number-date">
                {item.billing_number}
                <span className="ml5">|</span>
                {item.billing_date}
              </div>
            </div>
            <div className={Array.isArray(item.receivables) && item.receivables.length ? 'item-bill item-bill_pt56' : 'item-bill'}>
              {/* item头部内容 */}
              <div className="flex bill-title">
                <div className="img-txt">
                  <img src={getIconImg(item.bill_type)} alt="" />
                  <div>{item.bill_type}</div>
                </div>
                <div className="txtAlignR">
                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                  {`${item.billing_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </div>
              </div>
              <div className="flex">
                <div className="txt-des">
                  核销金额
                  <span className="ml5" />
                  <span className="v2210_moneyUnitMobileMR3">¥</span>
                  {`${item.returning_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </div>
                <div className="txtAlignR txt-des">开票金额</div>
              </div>
              <div className="flex">
                <div className="txt-des">
                  开票应收 
                  <span className="ml5" />
                  {`${item.unreceivable?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </div>
                <div />
              </div>
              {/* 线条 */}
              <div className={item.drownFlag ? 'bottom-line' : ''} />
              {
                Array.isArray(item.receivables) && item.receivables.length ? (
                  <div className="txt-des link-drown_txt" onClick={() => { handleShowList(index, item.drownFlag); }}>
                    <span>
                      关联
                      {item.relation_count}
                      个回款明细
                    </span>
                    <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                  </div>
                ) : ''
              }
              {
                item.drownFlag ? (
                  <div className="contract-main">
                    {
                      Array.isArray(item.receivables) ? (
                        item.receivables.map((listItem, listItemIndex) => (
                          <div className="contract-item" key={listItemIndex}>
                            <div className="item-line">
                              <div className="contract-label">
                                关联合同
                                {listItemIndex + 1}
                                ：
                              </div>
                              <div className="contract-txt">{listItem.contract_name}</div>
                            </div>
                            <div className="item-line">
                              <div className="contract-label">核销金额：</div>
                              <div className="contract-txt">{`${item.returning_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</div>
                            </div>
                            <div className="item-line">
                              <div className="contract-label">核销日期：</div>
                              <div className="contract-txt">{listItem.arrival_date}</div>
                            </div>
                          </div>
                        ))
                      ) : ''
                    }
                    {
                      item.drownFlag && item.relation_count > 10 ? (
                        <div className="show-tips">
                          <img src={tipsImg} alt="" />
                          <div>
                            其余
                            {item.relation_count - item.receivables.length}
                            条回款明细可前往账款管家查看
                          </div>
                        </div>
                      ) : ''
                    }
                  </div>
                ) : ''
              }
            </div>
          </div>
        ))}
      </div>
  
      <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile' : 'none'}>
        {/* <div className="filter" /> */}
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
