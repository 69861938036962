/*
 * pc端查账龄
 * @Author: your name
 * @Date: 2021-10-25 14:46:25
 * @LastEditTime: 2022-06-10 10:28:35
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\TableData\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/aging.scss';
import { useMount, useDebounce } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function Aging({ msg }) {
    Aging.propTypes = {
      msg: PropTypes.object,
    };
    const dataArr = msg?.info.list;
    const [pageSatus, setPageStatus] = useState(true);
    const [current, setCurrent] = useState(1);
    const [prev, setPrev] = useState(false);
    const [content, setContent] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);
    useMount(() => {
      if (current > 1 || prev) {
        if (current > Number(msg.info.total_page) || current === 0) return;
        setLoadingStatus(true);
        request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
          params: {
            ...msg.info.parameter,
            per_page: 3,
            current_page: current,
          },
        }).then((res) => {
          setLoadingStatus(false);
          setContent([...res.info.list]);
        });
      }
    }, [current]);
    useMount(() => {
      let timer;
      if (pageSatus) {
        timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        }, 300);
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {
        // eslint-disable-next-line
        timer && clearTimeout(timer);
      };
    });
    // 下一页
    const nextPage = useDebounce(() => {
      setPageStatus(false);
      setCurrent(current + 1);
      setPrev(false);
    }, 400);

    // 上一页
    const prevPage = useDebounce(() => {
      setPrev(true);
      setPageStatus(false);
      setCurrent(current - 1);
    });
    return (
      <div className={loadingStatus ? 'aging relative' : 'aging'}>
        <div className={loadingStatus ? 'mask' : 'none'} />
        <div className={loadingStatus ? 'loading' : 'none'}>
          <img src={loading} alt="" />
        </div>
        {
          (content || dataArr).map((ele, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div style={{ backgroundColor: '#F3F6FA' }} key={index}>
              <main id={`main${index}`}>
                <div style={{ marginBottom: '8px' }}>
                  <span>
                    <span style={{ color: '#3377FF' }}>
                      {ele.contract_name}
                    </span>
                    ｜ 
                    {ele.custom_name}
                  </span>
                </div>
                <div>
                  <span style={{ marginRight: '40px' }}>
                    合同金额：
                    <span style={{ color: '#FFAA00' }}>
                      <span className="v2210_moneyUnitMR3">¥</span>
                      {ele.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </span>
                  <span>
                    合同应收：
                    <span style={{ color: '#FFAA00' }}>
                      <span className="v2210_moneyUnitMR3">¥</span>
                      {ele.money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </span>
                </div>
              </main>
              <div className={index === (content || dataArr).length - 1 ? 'none' : ''} style={{ width: '540px', height: '1px', backgroundColor: '#E9EBEF', margin: '0 auto' }} /> 
            </div>
            ))
        }
        <div className={Number(msg.info.total) > 3 ? 'bottom' : 'none'}>
          {/* eslint-disable-next-line */}
          <div className={current === 1 || loadingStatus ? 'disabled' : 'color'} onClick={() => current === 1 || loadingStatus ? '' : prevPage()}>上一页</div>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) || loadingStatus ? 'disabled' : 'color'} onClick={() => current >= Number(msg.info.total_page) || loadingStatus ? '' : nextPage()}>下一页</div>
        </div>
      </div>
    );
}
