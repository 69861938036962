/* eslint-disable max-len */
/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 坏账31
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-10 10:11:07
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import { Pagination } from 'antd';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './baddebt.scss';
import { useMount } from '../../../../utils/hooks';
import { initBaddebtContractArr, updBaddebtContractDrownFlag, getBaddebtFirstList, appendBaddebtContractChild } from '../utils/index';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';
import drownImg from '@/images/drown_1124.png';

export default function ArBaddebtContractIndex({ msg, appendMessage }) {
  ArBaddebtContractIndex.propTypes = {
    msg: PropTypes.object,
    appendMessage: PropTypes.func, // 发送消息方法
  };
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 1); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [contractloadingStatus, setContractLoadingStatus] = useState(false); // 查看合同loading
  // console.log(msg, 'ArBaddebtContractIndex22222', content, msg.info?.list, totalPage); 
  useMount(() => {
    // 初始化list
    if (Array.isArray(content)) {
      content = initBaddebtContractArr(content);
      setContent(content);
    } 
    
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    // eslint-disable-next-line no-unreachable
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      // console.log('res', res);
      // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('info')) {
        const arr1 = res.info.list || [];
        msg.info.list = arr1;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent(arr1);
      }
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }

  // 展开下拉
  async function handleShowList(index, flag) {
    setContent(updBaddebtContractDrownFlag(content, index, flag));
    if (content[index].number > 0) {
      // 没有请求接口
      if (!content[index].requestFlag) {
        setContractLoadingStatus(index + 1);
        let dataInfo = await getBaddebtFirstList(msg, content[index]);
        setContractLoadingStatus(false);
        content[index].children = dataInfo?.info?.list || [];
        setContent(updBaddebtContractDrownFlag(content, index, flag));
      }
    }
    // setContent(updBaddebtContractDrownFlag(content, index, flag));
  }

  return (
    <div className={loadingStatus ? 'v2200-ArBaddebtContractIndex v1123_relative' : 'v2200-ArBaddebtContractIndex'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        Array.isArray(content) && content.length ? (
          <div className="baddebtMain">
            {
              content.map((item, index) => (
                <div className="baddebtList" key={index}>
                  {/* 合同loading */}
                  {
                    contractloadingStatus === index + 1 ? (
                      <div className="contractDialogLoading">
                        <div className="loading-main">
                          <div className={contractloadingStatus === index + 1 ? 'mask' : 'none'} />
                          <div className={contractloadingStatus === index + 1 ? 'loading' : 'none'}>
                            <img src={loading} alt="" />
                          </div>
                        </div>
                      </div>
                    ) : ''
                  }
                  <div className="list-title">{item.custom_name}</div>
                  <div className="list-money">
                    <div className="money-title">
                      <div className="bad-money">
                        坏账金额：
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${item.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </div>
                      <div className="link-contract" onClick={() => { handleShowList(index, item.drownFlag); }}>
                        <div>
                          共关联
                          {item.number}
                          个合同
                        </div>
                        <img src={drownImg} alt="" className={item.drownFlag ? 'img-up' : ''} />
                      </div>
                    </div>
                
                    <div style={{ display: item.drownFlag && item.number ? 'block' : 'none' }}>
                      {
                        item.requestFlag && Array.isArray(item.children) && item.children.length ? (
                          <div className="childList">
                            {
                              item.children.map((el, index1) => (
                                <div className="contract-ct" key={index1}>
                                  <div>
                                    <span className="fontW700">{el.contract_name}</span>
                                    <span className="mlr5">|</span>
                                    跟进人：
                                    {el.followup_name}
                                  </div>
                                  <div className="mt8 money-date">
                                    <div className="w260">
                                      坏账金额：
                                      <span className="color-FF475A">
                                        <span className="v2210_moneyUnitMR3">¥</span>
                                        {`${el.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                      </span>
                                      <span className="mlr5">/</span>
                                      <span className="v2210_moneyUnitMR3">¥</span>
                                      {`${el.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </div>
                                    <div>
                                      坏账日期：
                                      {el.max_date}
                                    </div>
                                  </div>
                                  <div className="mt8">
                                    坏账原因：
                                    {el.reason || '-'}
                                  </div>
                                
                                </div>
                              ))
                            }
                            {/* 显示展示多少合同 */}
                            {
                              item.number > msg.info.per_page ? (
                                <div className="selLinkContract" onClick={() => { appendBaddebtContractChild(msg, item, appendMessage); }}>
                                  查看全部
                                  {item.number}
                                  个关联合同 
                                  <span className="mlr5">&gt;</span>
                                </div>
                              ) : ''
                            }
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                </div>
              ))
            }
            {
              Number(totalPage) > msg.info.per_page 
              ? (
                <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
              ) : ''
            }
          </div>
        ) : ''
      }
    </div>
  );
}
