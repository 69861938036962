/*
pc端点击查流水的账户组件
 * @Author: your name
 * @Date: 2021-12-06 11:25:55
 * @LastEditTime: 2022-01-25 18:12:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\Accounts\index.js
 */
// 参考Accounts 组件注释
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jianshe from '@/images/jianshe.png';
import zhaoshang from '@/images/zhaoshang.png';
import yinhangka from '@/images/yinhangka.png';
import nolook from '@/images/nolook.png';
import islook from '@/images/islook.png';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import '@/style/DetailAccount.scss';
import Robot from '@/images/robot_logo.png';
// eslint-disable-next-line import/extensions
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import bankName from '../../utils/bank';

export default function DetailAccount({ msg, allAccountCallback, appendMessage }) {
  // 账户类型   账户类型:\n1：银行账户\n2：现金账户\n第三方账户（\n3：支付宝账户\n4：微信账户\n5：其他账户;
    DetailAccount.propTypes = {
        msg: PropTypes.object,
        allAccountCallback: PropTypes.func,
        appendMessage: PropTypes.func,
    };
    const [flagStatusObj, setFalgStatusObj] = useState({});
    let list = msg?.info.list.info;
    list = returnData(list);
    const bankList = [jianshe, zhaoshang, yinhangka];
    const iconList = [0, bankList, xianjin, zhi, wei, qita];
    const [pageSatus, setPageStatus] = useState(true);
    const [current, setCurrent] = useState(1);
    const [prev, setPrev] = useState(false);
    const [content, setContent] = useState(null);

    useMount(() => {
      if (current > 1 || prev) {
        if (current > Number(msg.info.list.total_page) || current === 0) return;
        request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.api}`, {
          params: {
            ...msg.parameter,
            per_page: 3,
            current_page: current,
          },
        }).then((res) => {
          setContent(returnData([...res.info.list.info]));
        });
      }
    }, [current]);

    useMount(() => {
      let timer;
      if (pageSatus) {
        timer = setTimeout(() => {
          Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
        }, 300);
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {
        // eslint-disable-next-line
        timer && clearTimeout(timer);
      };
    });

    function returnData(item) {
      item.map((ele) => {
        if (ele.account_number) {
          if (ele.account_number.includes('@')) {
            ele.trueValues = ele.account_number;
            ele.falseValue = ele.trueValues.replace(/(.{3}).+(.{0}@.+)/g, '$1******$2');
          } else if (ele.account_number.length === 11) {
              ele.trueValues = ele.account_number.replace(/^(.{3})(.*)(.{4})/, '$1 $2 $3');
              ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(4, 8), '****');
            } else {
              ele.trueValues = ele.account_number.replace(/(.{4})/g, '$1 ');
            ele.falseValue = ele.trueValues.replace(ele.trueValues.slice(0, 18), '**** **** **** ***');
            }
        }
        if (ele.leb === 1 || ele.leb === 5) {
          if (ele.bank_name) {
            ele.bankIcon = bankName(ele.bank_name);
          } else {
            ele.bankIcon = yinhangka; 
          }
        }
        return ele;
      });
      return item;
    }
    // 是否脱敏
    function isLook(e, item) {
      e.stopPropagation();
      if (flagStatusObj[item.payment_id]) {
        setFalgStatusObj({
          ...flagStatusObj,
          [item.payment_id]: false,
        });
      } else {
        setFalgStatusObj({
          ...flagStatusObj,
          [item.payment_id]: true,
        });
      }
    }

    // 下一页
    function nextPage() {
      setPageStatus(false);
      setCurrent(current + 1);
      setPrev(false);
    }

    // 上一页
    function prevPage() {
      setPrev(true);
      setPageStatus(false);
      setCurrent(current - 1);
    }

    // 账户详情
    // eslint-disable-next-line 
    function detail(payment_id) {
      // eslint-disable-next-line 
      const { payment_method, bank_name, ...params } = msg.parameter;
      allAccountCallback();
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.api}`, {
        params: {
          ...params,
          per_page: 5,
          current_page: 1,
          payment_id,
        },
      }).then((res) => {
        appendMessage({ text: res }, 'left', 'nextPage', Robot, true, 'MoneyDetail');
      });
    }

    return (
      <div className="DetailAccount" style={{ marginTop: 8 }}>
        {
         (content || list).map((item) => (
           <div className={list.length ? 'accounts margin-bottom' : 'accounts'} key={item.payment_id} onClick={() => detail(item.payment_id)}>
             <div className="icon">
               <img src={item.leb === 1 ? item.bankIcon : iconList[item.leb]} alt="" />
             </div>
             <div className="middle-content">
               <div>
                 {item.payment_method} 
                 <span>
                   {item.bank_name ? `（${item.bank_name}）` : ''}
                 </span>
               </div>
               <div className={item.account_number ? '' : 'acc-margin-top'}>
                 <div className={item.account_number ? '' : 'none'} style={{ display: 'flex' }}>
                   <div>{flagStatusObj[item.payment_id] ? item.trueValues : item.falseValue}</div>
                   <div style={{ width: '16px', height: '16px', marginLeft: '8px', cursor: 'pointer' }} onClick={(e) => isLook(e, item)}>
                     <img src={flagStatusObj[item.payment_id] ? islook : nolook} alt="" />
                   </div>
                 </div>
               </div>
             </div>
           </div>
          ))
       }
        <div className={Number(msg.info.list.total) > 3 ? 'bottom' : 'none'}>
          <div className={current === 1 ? 'disabled' : 'color'} onClick={prevPage}>上一页</div>
          <div className={current >= Number(msg.info.list.total_page) ? 'disabled' : 'color'} onClick={nextPage}>下一页</div>
        </div>
      </div>
 
    );
}
