/*
pc报销组件
 * @Author: your name
 * @Date: 2021-12-08 17:13:50
 * @LastEditTime: 2022-01-25 18:24:58
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileRate\index.js
 */
import React, { useState } from 'react';
import '@/style/rate.scss';
import PropTypes from 'prop-types';
import success from '@/images/success.png';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';
import loading from '@/images/loading.png';

export default function Rate({ msg }) {
  Rate.propTypes = {
    msg: PropTypes.object,
  };
  // status参数说明: 0：未处理；1：已驳回；2：已同意申请 3：拒绝付款；4：申请人撤销申请；5：付款失败；6：已付款撤销；7：付款成功；9：付款中；
  const list = msg?.info.list;
  const twoStatus = ['待审批', '已驳回', '已通过', '已通过', '待审批', '已通过', 6, '已通过', '已通过', '已通过'];
  const threeStatus = ['待付款', '待付款', '待付款', '已拒绝', '待付款', '付款失败', 6, '已付款', '付款失败', '付款中'];
  const twoTime = [0, 4];
  const twoName = [4];
  const twoIcon = [5, 7, 9, 8];
  const threeTime = [...twoIcon, 3];
  const threeName = [...twoIcon, 2, 3];
  const threeNoaActive = [0, 1, 2, 4];
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [prev, setPrev] = useState(false);
  const [content, setContent] = useState(null); // 内容渲染
  const [loadingStatus, setLoadingStatus] = useState(false);

  useMount(() => {
    if (current > 1 || prev) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setContent([...res.info.list]);
      });
    }
  }, [current]);
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 下一页
  function nextPage() {
    setPageStatus(false);
    setCurrent(current + 1);
    setPrev(false);
  }

  // 上一页
  function prevPage() {
    setPrev(true);
    setPageStatus(false);
    setCurrent(current - 1);
  }
  return (
    <div className={loadingStatus ? 'rate-list relative' : 'rate-list'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        list && (content || list).map((item) => (
          <div className="rate" key={item.id}>
            <div className="step">
              <div style={{ width: '32px', height: '32px' }}>
                <img src={success} alt="" />
              </div>
              <div className="line" />
              <div className="step-two">
                {twoIcon.includes(item.status) ? <img src={success} alt="" /> : <div className="num">2</div>}
              </div>
              <div className={threeNoaActive.includes(item.status) ? 'line noactive' : 'line'} />
              <div className={threeNoaActive.includes(item.status) ? 'step-two noactiveIcon' : 'step-two'}>
                <div className="num">3</div>
              </div>
            </div>
            <div className="tijiao" style={{ display: 'flex', marginTop: '10px' }}>
              <div style={{ width: '204px' }}>
                {item.status === 4 ? '已撤销' : `提交${msg.leb}申请`}
              </div>
              <div style={{ width: '200px' }}>{item.status === 1 ? <span style={{ color: 'rgba(255, 71, 90, 1)' }}>已驳回</span> : twoStatus[item.status]}</div>
              {/* eslint-disable-next-line */}
              <div>{item.status === 5 || item.status === 3 || item.status === 8 ? <span style={{ color: 'rgba(255, 71, 90, 1)' }}>{threeStatus[item.status]}</span> : threeStatus[item.status]}</div>
            </div>
            <div className="name">
              <div style={{ width: '204px' }}>
                <div>{item.apply_user}</div>
                <div>{item.apply_time}</div>
              </div>
              <div style={{ width: '200px' }}>
                <div className={twoName.includes(item.status) ? 'none' : ''}>{item.approvl_user}</div>
                <div className={twoTime.includes(item.status) ? 'none' : ''}>{item.approvl_time}</div>
              </div>
              <div>
                <div className={threeName.includes(item.status) ? '' : 'none'}>{item.payment_user}</div>
                <div className={threeTime.includes(item.status) ? '' : 'none'}>{item.payment_time}</div>
              </div>
            </div>
          </div>
        ))
      }
      <div className={Number(msg.info.total) > 3 ? 'bottom' : 'none'}>
        {/* eslint-disable-next-line */}
        <div className={current === 1 || loadingStatus ? 'disabled' : 'color'} onClick={() => current === 1 || loadingStatus ? '' : prevPage()}>上一页</div>
        {/* eslint-disable-next-line */}
        <div className={current >= Number(msg.info.total_page) || loadingStatus ? 'disabled' : 'color'} onClick={() => current >= Number(msg.info.total_page) || loadingStatus ? '' : nextPage()}>下一页</div>
      </div>
    </div>
  );
}
