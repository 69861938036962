/*
pc端到期合同组件
 * @Author: your name
 * @Date: 2021-10-22 17:10:17
 * @LastEditTime: 2022-06-10 10:28:24
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\ChatForm\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/chat-form.scss';
import { useMount } from '../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function ChatForm({ msg }) {
  ChatForm.propTypes = {
    msg: PropTypes.object,
  };
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1); // 当前页数
  const [prev, setPrev] = useState(false); // 点击上一页则为true
  const [content, setContent] = useState(msg?.info.list || []);
  const [loadingStatus, setLoadingStatus] = useState(false);
  useMount(() => {
    // 分页请求
    if (current > 1 || prev) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: 3,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setContent([...Object.values(res.info.list)]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    setPageStatus(false);
    setCurrent(current + 1);
    setPrev(false);
  }

  // 上一页
  function prevPage() {
    setPrev(true);
    setPageStatus(false);
    setCurrent(current - 1);
  }
  return (
    <div className={loadingStatus ? 'ChatForm relative' : 'ChatForm'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        Array.isArray(content) && content.length ? (
          <div className="v2221-list_main">
            {content.map((ele, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="list-item" key={index}>
                <div className="item-content">
                  <div className="contract-main">
                    <span>
                      <span className="name-txt">
                        {ele.contract_name}
                      </span>
                      ｜ 
                      {ele.custom_name}
                    </span>
                  </div>
                  <div>
                    <span>
                      合同金额：
                      <span className="money-txt">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {ele.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : ''
      }
      <div className={Number(msg.info.total) > 3 ? 'bottom' : 'none'}>
        {/* eslint-disable-next-line */}
        <div className={current === 1 || loadingStatus ? 'disabled' : 'color'} onClick={() => current === 1 || loadingStatus ? '' : prevPage()}>上一页</div>
        {/* eslint-disable-next-line */}
        <div className={current >= Number(msg.info.total_page) || loadingStatus ? 'disabled' : 'color'} onClick={() => current >= Number(msg.info.total_page) || loadingStatus ? '' : nextPage()}>下一页</div>
      </div>
    </div>
  );
}
