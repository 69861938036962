/*
 * @Author: your name
 * @Date: 2021-10-13 10:57:01
 * @LastEditTime: 2022-07-08 15:53:57
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\apis\http.js
 */
// eslint-disable-next-line import/extensions
import request from '@/utils/request.js';

// 获取OSS签名的一些数据
export const ossKey = (params = {}, headers = {}) => request.instance.get(`${process.env.REACT_APP_BASEURL}/api/oss/key`, { params, headers });
// 对话接口
export const chat = (params = {}, headers = {}) => request.instance.post(`${process.env.REACT_APP_BASEURL}/api/chat`, params, headers);
// 获取用户信息
export const userInfo = (params = {}, headers = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/get_user_info`, { params, headers });
// 获取ASR语音识别鉴权
export const asr = (params = {}, headers = {}) => request.instance.post(`${process.env.REACT_APP_BASEURL}/api/asr`, params, headers);
// 获取pc客服二维码
export const qrcode = () => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/custom/service/qrcode`);
// 获取提示信息
export const tips = (params = {}, headers = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/introduce?service_key=robot`, { params, headers });
// 查看用户是否是第一次进Signser
export const firstTime = () => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/is_first_time`);
// 获取语音识别配置
export const asrConfig = () => request.instance.get(`${process.env.REACT_APP_BASEURL}/api/asr/config`);
// 查账户指定类型列表
export const accountList = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ac/account/list`, { params });
// 查流水
export const cashFlow = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ac/cash/flow`, { params });
// 查流水
export const expire = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ap/contract/expire`, { params });
// 首页推荐
export const recommendEntrance = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/recommend/entrance`, { params });
// 无答案推荐
export const recommendNoAnser = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/recommend/no_answer`, { params });
// 有答案推荐
export const RecommendMoreIntentMessages = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/recommend/more_intent_messages`, { params });
// 推荐点击事件
export const RecommendClick = (params = {}) => request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/recommend/click`, params);
// 费用报销借款的推送消息详情
export const getAcProjectBudgetRemind = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/api/zkgj/ac/project/budget/remind`, { params });
// 合同付款的推送消息详情
export const getAcProjectBudgetContract = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/api/zkgj/ac/project/budget/get/contract`, { params });
// 合同付款获取合同下项目分页
export const getAcProjectBudgetContractProject = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/api/zkgj/ac/project/budget/get/contract/project`, { params });
// 点赞点踩
export const chatFeedBack = (params = {}) => request.instanceZhangkuan.post(`${process.env.REACT_APP_BASEURL}/api/chat/feedback`, params);
// 账款管家埋点接口
export const getBuriedpoint = (params = {}) => request.instance.get(`${process.env.REACT_APP_ZKGJ_PROXY}/Api/buriedpoint/buriedpoint.php?origin=${params.origin}&p=${params.p}`);
// 获取用户权限接口
export const getUserAuth = (params = {}) => request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/get_user_auth`, params);
