/*
 * pc端查所有账户组件
 * @Author: your name
 * @Date: 2021-12-06 09:51:46
 * @LastEditTime: 2022-01-25 18:01:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\AllAccounts\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/allAccounts.scss';
import wei from '@/images/wei@2x.png';
import xianjin from '@/images/xianjin@2x.png';
import zhi from '@/images/zhi@2x.png';
import qita from '@/images/qita.png';
import yinhangka from '@/images/yinhangka.png';
// eslint-disable-next-line import/extensions
import { accountList } from '@/apis/http';
import { useMount, useScrollBottom } from '../../utils/hooks';
import Robot from '@/images/robot_logo.png';

// eslint-disable-next-line no-unused-vars
export default function AllAccounts({ allAccountCallback, appendMessage, msg }) {
  AllAccounts.propTypes = {
    allAccountCallback: PropTypes.func, // 发送消息等待期间的过渡动画
    appendMessage: PropTypes.func, // 发送消息方法
    msg: PropTypes.object, // 消息详情
  };
  useScrollBottom();
  const [accounts, setAccounts] = useState([
    {
        icon: yinhangka,
        leb_name: '银行账户',
        leb: 1,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: xianjin,
        leb_name: '现金账户',
        leb: 2,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: zhi,
        leb_name: '支付宝账户',
        leb: 3,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: wei,
        leb_name: '微信账户',
        leb: 4,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
    {
        icon: qita,
        leb_name: '其他账户',
        leb: 5,
        leb_num: 0,
        link: '/api/ap/account/list',
    },
  ]); // 初始化accounts数据为零时后端不返回苏剧则用我们初始化的数据
  
  useMount(() => {
    const list = msg?.info.list;
    const list2 = accounts;
    if (list && list.length) {
      list.forEach((item) => {
        list2[item.leb - 1].leb_num = item.leb_num;
      });
      setAccounts([...list2]);
    }
  });
  /**
   * @description 点击查看指定账户
   * @param {*} number 账户数量 数量为0时不可点击
   * @param {*} leb 账户类型
   * @returns 
   */
   function handleAccount(number, leb) {
    if (number === 0) return;
    allAccountCallback();
    accountList({
      ...msg.info.parameter,
      leb: leb.toString(),
      per_page: '3',
      current_page: '1',
    }).then((res) => {
      appendMessage({ text: res }, 'left', 'nextPage', Robot, true, 'Accounts');
    });
   }
    return (
      <div className="allAccounts" style={{ marginTop: 8 }}>
        {
            accounts.length && accounts.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={element.leb_num === 0 ? 'normal' : ''} key={index} onClick={() => handleAccount(element.leb_num, element.leb)}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20px', height: '20px', marginRight: 4 }}>
                    <img src={element.icon} alt="" />
                  </div>
                  {element.leb_name}
                </div>
                <div>
                  {element.leb_num} 
                  个
                </div>  
              </div>
              ))
        }
      </div>
    );
}
