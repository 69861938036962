/* eslint-disable max-len */
/*
 * @Author: your name
 * @Date: 2022-01-25 09:47:43
 * @LastEditTime: 2022-07-08 21:21:00
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%
 * @FilePath: \Signser-web\src\containers\MobileFirstSentence\index.js
 */
// 移动端第一次进入signser的对话样式
import React, { useState, useEffect } from 'react';
import '@/style/mobile-table-two.scss';
import PropTypes from 'prop-types';
import { useMount } from '../../utils/hooks';
import { recommendClick, getRecommendEntrance } from '../../utils/utils';
import '@/style/MobileFirstSentence.scss';
import shuaxin from '@/images/shuaxin.svg';
import selectShuanXin from '@/images/selectShuanXin.svg';

let start = false;
// sendFAQ sendFAQ 直接点击发送
export default function MobileFirstSentence({ pcSend, id, appendMessage, setIsShowFooterInput }) {
    MobileFirstSentence.propTypes = {
        // sendFAQ: PropTypes.func,
        pcSend: PropTypes.func,
        appendMessage: PropTypes.func,
        setIsShowFooterInput: PropTypes.func,
        id: PropTypes.string,
    };
    // eslint-disable-next-line
    // const initArr = [ '我的逾期应收', '我跟进的合同有没有逾期', '查逾期的应收合同', '查逾期的应付合同', '查我上个月的跟进的合同有没有逾期', '账龄在90天内的应收账款', '我跟进的30天内的应收账款', '产品部门跟进的账龄在90天内的应收账款', '这个月开了多少普票', '这个月有多少发票', '这个月开了多少合同发票', '已付款未收票的合同还有多少', '西安朋客信息科技有限公司有没有已付款未收票的合同', '还有多少发票没有收款', '还有多少收款没有开票', '我的销售额', '销售额', '产品部这个月的销售额', '今年的销售额', '我上个月的销售额', '我们公司的销售额', '这个月公司业绩排名', '我们公司这个月谁的销售额最高', '产品部门的销售业绩', '张三这个月的销售额怎么样', '这个月账户支出有多少', '查一下这个月的账户收入', '查一下这个月的账户支出', '这个月账户收入有多少', '这个月的交易流水', '看一下十月份的现金流明细', '查上个月的交易流水'];
    // const [arr, setArr] = useState(initArr);
    const [arr, setArr] = useState([]);
    const [random, setRandom] = useState(Math.floor(Math.random() * arr.length));
    const [timer, setTimer] = useState(null);
    const [iconStatus, setIconStatus] = useState(shuaxin);
    // eslint-disable-next-line prefer-const
    let [messageInfo, setMessageInfo] = useState(null);
    useMount(async () => {
      // 推荐列表初始化
      // eslint-disable-next-line max-len
      getRecommendEntrance(id, setMessageInfo, setArr, setRandom, pcSend, appendMessage, setIsShowFooterInput, 'web');
    });
    // 点击换一批页面上的数据发生改变
    function change() {
        setRandom(Math.floor(Math.random() * arr.length));
        setIconStatus(selectShuanXin);
        const iconTimer = setTimeout(() => {
          setIconStatus(shuaxin);
          clearTimeout(iconTimer);
        }, 200);
    }
    function onTouchStart(e) {
        start = false;
        const timeOut = setTimeout(() => {
            if (!start) {
              // 推荐点击事件
              recommendClick('recommendEnterClick', messageInfo, e.target.innerText.split('、')[1]);
              // sendFAQ(e.target.innerText.split('、')[1]);
              pcSend(e.target.innerText.split('、')[1]);
            }
        }, 100);
        setTimer(timeOut);
    }
    useEffect(() => {
        clearTimeout(timer);
    }, []);
    return (
      <div>
        <div style={{
          fontSize: 'calc(28rem/75)',
          lineHeight: 'calc(38rem/75)',
          fontFamily: 'PingFang SC-Regular, PingFang SC',
          fontWeight: '400',
          color: '#333333',
        }}>
          {/* {
            messageInfo && messageInfo.message_type === 1 ? '' : '您可以试试这么问：'
          } */}
        </div>
        <div className="mobile-table-two">
          {
            arr.length >= 1 ? (
              <div onTouchStart={(e) => onTouchStart(e)}>
                1、
                {
                  // eslint-disable-next-line no-nested-ternary
                  arr.length === 1 ? arr[0] : (random === 0 ? arr[random + 1] : arr[random - 1])
                }
              </div>
            ) : ''
          }
          {
            arr.length >= 2 ? (
              <div onTouchStart={(e) => onTouchStart(e)}>
                2、
                {
                  // eslint-disable-next-line no-nested-ternary
                  arr.length === 2 ? arr[1] : arr[random]
                }
              </div>
            ) : ''
          }
          {
            arr.length >= 3 ? (
              <div onTouchStart={(e) => onTouchStart(e)}>
                3、
                {
                  // eslint-disable-next-line no-nested-ternary
                  arr.length === 3 ? arr[2] : (random + 1 === arr.length ? arr[random - 2] : (random === 0 ? arr[random + 2] : arr[random + 1]))
                }
              </div>
            ) : ''
          }
        </div>
        {
          arr.length <= 3 ? '' : (
            <div className="MobileFirstSentence" onTouchStart={change}>
              <div className="img">
                <img src={iconStatus} alt="" />
              </div>
              <div className={iconStatus === shuaxin ? 'font' : 'font select-color'}>换一批</div>
            </div>
          )
        }
      </div>
    );
}
