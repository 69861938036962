import React from 'react';
import PropTypes from 'prop-types';
import Accounts from '../Accounts';
import '@/style/nextPage.scss';
import Overdue from '../Overdue';
import Expire from '../Expire';
import Payment from '../Payment';
import Aging from '../Aging';
import Rate from '../Rate';
import MoneyDetail from '../MoneyDetail';
import DetailAccount from '../DetailAccount';

// eslint-disable-next-line 
export default function NextPage({ msg, updateMsg, id, nextPageType, allAccountCallback, appendMessage }) {
    NextPage.propTypes = {
        msg: PropTypes.object,
        updateMsg: PropTypes.func,
        nextPageType: PropTypes.string,
        id: PropTypes.string,
        appendMessage: PropTypes.func,
        allAccountCallback: PropTypes.func,
    };
    let value = '';
    if (msg.info?.list || msg.info?.info) {
      switch (nextPageType) {
        case 'Overdue':
          value = <Overdue id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Expire':
          value = <Expire id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Payment':
          value = <Payment id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Aging':
          value = <Aging id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Accounts':
          // eslint-disable-next-line 
          value = <Accounts id={id} msg={msg} updateMsg={updateMsg} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />;
          break;
        case 'Rate':
          // eslint-disable-next-line 
          value = <Rate id={id} msg={msg} updateMsg={updateMsg} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />;
          break;
        case 'MoneyDetail':
          value = <MoneyDetail id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'DetailAccount':
          value = <DetailAccount id={id} msg={msg} updateMsg={updateMsg} />;
          break;
        case 'Text':
          value = '';
          break;
        default:
          break;
      }
    }

    return (
      <div className="next-page">
        {/* eslint-disable-next-line */}
        <header id={msg._id} className="header" dangerouslySetInnerHTML={{ __html: msg.title }} />
        {msg.info?.list || msg.info?.info ? value : ''}
      </div>
    );
}
