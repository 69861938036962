/*
pc端流水组件
 * @Author: your name
 * @Date: 2021-12-07 14:45:10
 * @LastEditTime: 2022-01-25 18:23:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MoneyDetail\index.js
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/moneyDetail.scss';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';
import loading from '@/images/loading.png';

export default function MoneyDetail({ msg }) {
  MoneyDetail.propTypes = {
    msg: PropTypes.object,
  };
  const dataArr = msg?.info.info;
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [prev, setPrev] = useState(false);
  const [content, setContent] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const typeStatus = [0, '客户', '客户', '收款人', '客户', '', '供应商', '客户', '客户', '供应商', '', '客户', '收款人', '收款方', '收款人'];
  useMount(() => {
    if (current > 1 || prev) {
      if (current > Number(msg.info.total_page) || current === 0) return;
      setLoadingStatus(true);
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 5,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        setContent([...res.info.info]);
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 下一页
  function nextPage() {
    setPageStatus(false);
    setCurrent(current + 1);
    setPrev(false);
  }

  // 上一页
  function prevPage() {
    setPrev(true);
    setPageStatus(false);
    setCurrent(current - 1);
  }
  return (
    <div className={loadingStatus ? 'moneyDetail relative' : 'moneyDetail'} style={{ marginTop: 8 }}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        dataArr && (content || dataArr).map((item, index) => (
          // eslint-disable-next-line
          <div className="money" key={index} style={{ padding: '8px 0px' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <div style={{
                marginRight: '35px',
                fontSize: '10px',
                fontFamily: 'PingFangSC-Semibold, PingFang SC',
                fontWeight: '600',
                color: '#333333',
                lineHeight: '14px',
              }}>
                {item.date}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="flex">
                  <div style={{ width: '48px' }}>摘要：</div>
                  <div style={{ width: '96px' }}>{item.description}</div>
                </div>
                <div className={typeStatus[item.type] ? 'flex' : 'none'} style={{ marginTop: '8px' }}>
                  <div style={{ width: '48px', display: 'flex', alignItems: 'center' }}>
                    {typeStatus[item.type]}
                    ：
                  </div>
                  {/* {item.name} */}
                  <div style={{ width: '96px' }}>{item.name}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex" style={{ justifyContent: 'space-between' }}>
                <div>
                  {Number(item.money) >= 0 ? '收入' : '支出'}
                  ：
                </div>
                <div style={{ width: '100px', textAlign: 'right' }}>{Number(item.money) >= 0 ? <span style={{ color: 'rgba(61, 204, 104, 1)' }}>{`+${item.money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span> : <span style={{ color: 'rgba(255, 71, 90, 1)' }}>{item.money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}</div>
              </div>
            </div>
          </div>
        ))
      }
      <div className={Number(msg.info.total) > 5 ? 'bottom' : 'none'}>
        {/* eslint-disable-next-line */}
        <div className={current === 1 || loadingStatus ? 'disabled' : 'color'} onClick={() => current === 1 || loadingStatus ? '' : prevPage()}>上一页</div>
        {/* eslint-disable-next-line */}
        <div className={current >= Number(msg.info.total_page) || loadingStatus ? 'disabled' : 'color'} onClick={() => current >= Number(msg.info.total_page) || loadingStatus ? '' : nextPage()}>下一页</div>
      </div>
    </div>
  );
}
