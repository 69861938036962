/*
移动流水组件
 * @Author: your name
 * @Date: 2021-12-08 11:45:36
 * @LastEditTime: 2022-04-12 10:37:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileMoneyDetail\index.js
 */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import '@/style/mobileMoneyDetail.scss';
import right from '@/images/icon-right.png';
// eslint-disable-next-line
import request from '@/utils/request';
import { useMount } from '../../utils/hooks';
import loading from '@/images/loading.png';

let start = false;
export default function MobileMoneyDetail({ msg }) {
  MobileMoneyDetail.propTypes = {
    msg: PropTypes.object,
  };
  let dataArr = msg?.info.info;
  const typeStatus = [0, '客户', '客户', '收款人', '客户', '', '供应商', '客户', '客户', '供应商', '', '客户', '收款人', '收款方', '收款人'];
  const [force, setForce] = useState(null);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [pickArr, setPickArr] = useState([]);
 // 请求分页数据
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params: {
          ...msg.parameter,
          per_page: 5,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        if (current < 3) {
          dataArr = [...dataArr, ...res.info.info];
          setForce(dataArr);
        } else {
          setForce([...force, ...res.info.info]);
        }
      });
    }
  }, [current]);
  // 点击下一页之后则不开启下拉到最底部
  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });
  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }
 // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, 5));
      }
      clearTimeout(time);
    }, 100);
  }

  function onTouchMove() {
    start = true;
  }
  return (
    <div className="mobileMoneyDetail">
      {
        (pickArr.length ? pickArr : force || dataArr).map((item, index) => (
          // eslint-disable-next-line
          <Fragment key={index}>
            <div className={Number(msg.info.total) > 5 ? 'money lastmoney' : 'money'}>
              <div className="date">{item.date}</div>
              <div>
                <div className="flex">
                  <div className="width">
                    {Number(item.money) >= 0 ? '收入' : '支出'}
                    ：
                  </div>
                  <div>{Number(item.money) >= 0 ? <span style={{ color: 'rgba(61, 204, 104, 1)' }}>{`+${item.money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span> : <span style={{ color: 'rgba(255, 71, 90, 1)' }}>{item.money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}</div>
                </div>
                <div className="flex" style={{ marginTop: 'calc(16rem/75)' }}>
                  <div className="width">摘要：</div>
                  <div>{item.description}</div>
                </div>
                <div className={typeStatus[item.type] ? 'flex' : 'none'} style={{ marginTop: 'calc(16rem/75)' }}>
                  <div className={typeStatus[item.type] ? 'width' : 'none'} style={{ display: 'flex', alignItems: 'center' }}>
                    {typeStatus[item.type]}
                    ：
                  </div>
                  <div style={{ width: 'calc(300rem/75)' }}>{item.name}</div>
                </div>
              </div>
            </div>
          </Fragment>
        ))
      }
      <div className={Number(msg.info.total) > 5 ? 'filter-bottom' : 'none'}>
        <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? '' : 'filter'} />
        <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
          {/* eslint-disable-next-line */}
          <div className={current >= Number(msg.info.total_page) &&  pickArr.length === 0  ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
          <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
            <div className="pack-up">
              收起
            </div>
            <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
