/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/*
底部输入框组件 点击进入实时语音
 * @Author: 一周
 * @Date: 2021-10-11 17:30:46
 * @LastEditTime: 2022-07-07 14:20:53
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\containers\Demo\index.js
 */
import React, { useRef, useState, useEffect } from 'react';
import '@/style/footer-input.scss';
import PropTypes from 'prop-types';
import v2511voiceLight from '@/images/v2511voiceLight.png';
import v2511voiceDefault from '@/images/v2511voiceDefault.png';
import v2511sendLight from '@/images/v2511sendLight.png';
import v2511sendDefault from '@/images/v2511sendDefault.png';

// eslint-disable-next-line quotes
const FooterInput = ({ startVoice, pcSend, silderContent, isShowFooterInput }) => {
  FooterInput.propTypes = {
    startVoice: PropTypes.func,
    pcSend: PropTypes.func,
    silderContent: PropTypes.string,
    isShowFooterInput: PropTypes.bool,
  };
  const textareaRef = useRef(); // 获取输入框的dom对象
  const [textareaValue, setTextareaValue] = useState(''); // 输入框的值
  // eslint-disable-next-line no-unused-vars
  const [maxLengthStatus, setMaxLengthStatus] = useState(false); // 设置输入框是否出现最大数字
  const [disabled, setDisabled] = useState(true); // 设置发送按钮是否置灰
  useEffect(() => {
    if (silderContent) {
      setTextareaValue(silderContent);
      setDisabled(false);
    }
  }, [silderContent]);
  /**
   * @description 处理用户输入的内容
   * @param {*} value 用户输入内容
   */
  function textareaChange(value) {
    const TEXTAREAVALUE = value;
    if (TEXTAREAVALUE.trim()) {
      setDisabled(false);
      if (value.length >= 201) {
        setDisabled(true);
        setMaxLengthStatus(true);
      } else {
        setMaxLengthStatus(false);
      }
    } else {
      setMaxLengthStatus(false);
      setDisabled(true);
    }
    setTextareaValue(value);
  }
  /**
   * @description 按回车键发送内容
   * @param {*} e 事件对象
   */
  function textareaKeyDown(e) {
    if (!e.shiftKey && e.keyCode === 13) {
      e.cancelBubble = true;
      e.preventDefault();
      e.stopPropagation();
      if (disabled) {
        return;
      }
      pcSend(textareaValue);
  }   
  }
  function send() {
    pcSend(textareaValue);
  }
  return (
    <>
      {
        isShowFooterInput ? (
          <footer className="footer-input">
            <div className="voice-content">
              <div className="input-ct">
                {/*  maxLength="200" */}
                <textarea autoFocus maxLength="999" className="textarea" rows="5" placeholder="Signser为您服务, 请输入您的问题" value={textareaValue} ref={textareaRef} onChange={(e) => textareaChange(e.target.value)} onKeyDown={(e) => textareaKeyDown(e)} />
              </div>
              <div className="voice-send">
                <div className="voiceImg" onClick={() => startVoice()}>
                  <img src={v2511voiceDefault} />
                  <img src={v2511voiceLight} className="lignt-img" />
                </div>
                <div className="split-line" />
                <div className="send-message">
                  {
                    disabled ? (
                      <div className="sendImg">
                        <img src={v2511sendDefault} />
                        {/* <img src={v2511sendLight} className="lignt-img" /> */}
                      </div>
                    ) : (
                      <div className="sendImg2" onClick={send}>
                        <img src={v2511sendLight} />
                      </div>
                    )
                  }
                  <div className={maxLengthStatus ? 'maxLength' : 'none'}>
                    <span>{textareaValue.length }</span>
                    <span className="a">/200</span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        ) : ''
      }
    </>

  );
};
export default FooterInput;
